import React, { useState } from "react";
import StakePools from "../components/List/StakePools";
import { list } from "../data/StakingPoolLists";

function Home() {
  const [poolList, setPoolList] = useState(list);
  const discoverStyle = {
    background:"#3147EB",
    color:"#FFFFFF",
  };

  return (
    <>
      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-lg-6">
            <div className="head-bar" />
            <h1 className="display-5 lh-1 my-3 title-text fw-600">
              Just stake some tokens to earn.
            </h1>
            <p className="lead py-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              sem libero, malesuada eget dolor eu, ornare cursus metus. Vivamus
              vitae tortor a enim sollicitudin tincidunt. Pellentesque habitant
              morbi tristique senectus et netus et malesuada fames ac turpis
              egestas.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button
                type="button"
                className="btn btn-lg px-4 me-md-2 rounded"
                style={discoverStyle}
              >
                Discover
              </button>
            </div>
          </div>
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              src="assets/images/misc/hero.png"
              className="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <StakePools poolList={poolList} />
      </div>
    </>
  );
}

export default Home;
