export const periodeList  = [
    {
      label: '1 Month',
      duration:'30d',
    },
    {
      label: '3 Months',
      duration:'90d',
    },
    {
      label: '6 Months',
      duration:'180d',
    },
    {
      label: '1 Year',
      duration:'365d',
    },
  ];
  