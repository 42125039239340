export const list  = [
    {
      title: 'Stake your WMIX Staking',
      subtitle:'Reward WMIX Staking.',
      label:'$WMIX',
      labelReward:'$WMIX',
      icon:{
        stakeCoin:'assets/images/icon/icon_coinbase.png',
        rewardCoin:'assets/images/icon/icon_coinbase.png'
      },
      apy:{
        label:'APY',
        value:'15%',
      },
      totalStake:{
        label:'Total Stake',
        value:'100.000 WMIX'
      },
      totalStaker:{
        label:'Total Staker',
        value:'410'
      },
      contract:'0xbeAd63Fd8Ad22a44DdA37004cdF77b54486fc2aB',
      link: '/staking/0xbeAd63Fd8Ad22a44DdA37004cdF77b54486fc2aB',
      status:'participate'
    },
    {
        title: 'Stake your USDT',
        subtitle:'Reward USDT.',
        label:'USDT',
        labelReward:'USDT',
        icon:{
            stakeCoin:'assets/images/icon/icon_usdt.png',
            rewardCoin:'assets/images/icon/icon_usdt.png'
        },
        apy:{
          label:'APY',
          value:'14%',
        },
        totalStake:{
          label:'Total Stake',
          value:'100.000 USDT'
        },
        totalStaker:{
          label:'Total Staker',
          value:'230'
        },
        contract:'0x7B80ec4E3F1e2Bf0efaD7F1E8E413c4a329cDE88',
        link: '/staking/0x7B80ec4E3F1e2Bf0efaD7F1E8E413c4a329cDE88',
        status:'participate'
      },
    //   {
    //     title: 'Stake your $SHIB',
    //     subtitle:'Reward $BUSD.',
    //     icon:{
    //         stakeCoin:'assets/images/icon/icon_shib.png',
    //         rewardCoin:'assets/images/icon/icon_bnb.png'
    //     },
    //     apy:{
    //       label:'APY',
    //       value:'10%',
    //     },
    //     totalStake:{
    //       label:'Total Stake',
    //       value:'100.000 SHIB'
    //     },
    //     totalStaker:{
    //         label:'Total Staker',
    //         value:'320'
    //     },
    //     contract:'-',
    //     link: '/soon',
    //     status:'upcoming'
    //   },
  ];
  