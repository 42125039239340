
import AbiToken from "../abi/AbiToken.json";
import AbiStakingPool from "../abi/AbiStakingPool.json";
export const walletProvider = {
    METAMASK: 'metaMask',
    TRUSTWALLET: 'trustWallet',
    SAFEPAL: 'safePal',
    WALLET_CONNECT: 'walletConnect'
};

//TESTNET
export const RPC_NODE_TESTNET={
    http:"https://mainnet-rpc.miexs.com/",
    wss:"wss://mainnet-rpc.miexs.com/ws",
}

export const RPC_NODE={
    http:"https://mainnet-rpc.miexs.com",
    wss:"wss://mainnet-rpc.miexs.com/ws",
}

export const TOKEN_STAKE ={
    address:'0x0530b71eDceafCACd75c3c7BE1062Bbe2ec166C2',
    abi: AbiToken
}

export const POOL_STAKE ={
    address:'0xbeAd63Fd8Ad22a44DdA37004cdF77b54486fc2aB',
    abi: AbiStakingPool
}